<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      persistent
      :max-width="
        current_view == 'CREATE'
          ? '1000px'
          : current_view == 'ENTER_PASSWORD'
          ? '400px'
          : '500px'
      "
      v-model="dialogApproveRejectIssuer"
    >
      <v-card>
        <v-toolbar dark color="secondary" dense class="elevation-0">
          <v-icon small class="mr-2">{{
            StoreObj.action == "CREATE" ? "mdi-plus" : "mdi-information"
          }}</v-icon>
          <div>
            {{
              StoreObj.action == "CREATE" ? "Create Issuer" : "Issuer Details"
            }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogApproveRejectIssuerEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text v-if="current_view == 'CREATE'">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-6">
                <div class="heading3 mx-1 font-weight-bold">
                  Business Details :
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Issuer Name*"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.issuer_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Issuer Type*"
                  :rules="[(v) => !!v || 'Required']"
                  :items="GetAllIssuerTypesList"
                  item-text="issuer_type"
                  item-value="issuer_type"
                  v-model="create_issuer.issuer_type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Partner*"
                  :rules="[(v) => !!v || 'Required']"
                  :items="GetAllPartnersList"
                  item-text="partner_name"
                  item-value="partner_id"
                  v-model="create_issuer.partner_id"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  class=" field_height field_label_size FontSize"
                  label="Country *"
                  readonly
                  :items="GetAllSupportedCountryList"
                  item-value="country_name"
                  item-text="country_name"
                  :rules="[(v) => !!v || 'Country is required']"
                  v-model="create_issuer.country"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.country_name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Please Select Identification Type"
                  :items="identificationTypeItems"
                  v-model="create_issuer.identification_type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Business Identification No. *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.identification_number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-0 mt-n3">
                <div class="heading3 mx-1 font-weight-bold">
                  Registered Business Address :
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Address 1 *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.issuer_address_1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Address 2 *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.issuer_address_2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="State *"
                  :items="stateList"
                  item-text="name"
                  item-value="name"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.state"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="City *"
                  :items="citiesList"
                  item-text="name"
                  item-value="name"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.city"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="District *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.district"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Postal Code *"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,6}$/.test(v) ||
                      'Postal Code Is Invalid',
                  ]"
                  v-model="create_issuer.postal_code"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                <div class="heading3 mx-1 font-weight-bold mt-n3">
                  Business Contact Details :
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Name *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.contact_user_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Email ID *"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => /.+@.+/.test(v) || 'Invalid',
                  ]"
                  v-model="create_issuer.contact_email_id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-select
                  outlined
                  dense
                  readonly
                  :items="GetAllSupportedCountryList"
                  item-value="country_code"
                  item-text="country_code"
                  class="field_height field_label_size FontSize"
                  label="Country Code *"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.country_code"
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.country_code }}
                  </template>
                  <template slot="item" slot-scope="{ item }"
                    >{{ item.country_name }}({{ item.country_code }})
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Contact Number *"
                  :rules="[
                    (v) =>
                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) ||
                      'Invalid',
                    (v) => (v && v.length < 11) || 'Invalid',
                    (v) => (v && v.length > 9) || 'Invalid',
                  ]"
                  v-model="create_issuer.contact_number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                <div class="heading3 mx-1 font-weight-bold mt-n3">
                  Verification Details :
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                <v-checkbox
                  dense
                  class="my-0 py-0"
                  label="Enable Verification Requests"
                  v-model="create_issuer.enable_verification_requests"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Currency Type*"
                  :items="currencyItems"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="create_issuer.currency_type"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                <v-text-field
                  outlined
                  dense
                  :disabled="
                    create_issuer.enable_verification_requests == false
                  "
                  readonly
                  class=" field_height field_label_size FontSize"
                  label="Verification Fee *"
                  :rules="[
                    (v) =>
                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,64}$/.test(v) ||
                      'Invalid',
                  ]"
                  v-model="create_issuer.issuer_verification_fee"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-if="current_view == 'UPLOAD_LOGO'" class="">
          <v-row>
            <v-col cols="12" sm="12" md="12" xs="12" align="center">
              <v-card
                outlined
                @click="checkImagePresent()"
                height="200px"
                width="300px"
                align="center"
                class="overflow-hidden mt-4"
              >
                <v-fab-transition v-if="imgSrc != null && imgSrc != ''">
                  <v-btn
                    absolute
                    top
                    right
                    dark
                    x-small
                    fab
                    color="red"
                    class="mt-8"
                    @click.stop="(imgSrc = null), (uploadedImage = false)"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-fab-transition>
                <v-img
                  v-if="imgSrc != null && imgSrc != ''"
                  :src="imgSrc"
                  :lazy-src="imgSrc"
                  max-width="200px"
                  class="mt-8"
                ></v-img>
                <div v-else>
                  <input
                    ref="excel-upload-input"
                    accept="image/*"
                    class="excel-upload-input"
                    type="file"
                    @change="handleClick"
                  />
                  <v-icon class="mt-16">mdi-upload</v-icon>
                  <div align="center">Upload Business Logo</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-form ref="commentForm">
            <v-text-field
              :rules="[(v) => !!v || 'Required']"
              v-if="rejectAction == true"
              dense
              outlined
              class="field_height field_label_size FontSize mx-6 mt-3"
              label="Reason for Rejection*"
              v-model="reason"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4 mr-2">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            class="borderRadius text-capitalize"
            color="secondary"
            @click="dialogApproveRejectIssuerEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            v-if="current_view == 'CREATE'"
            small
            :loading="loading"
            class="borderRadius text-capitalize"
            color="secondary"
            @click="current_view = 'UPLOAD_LOGO'"
            ><v-icon small class="mr-1">mdi-chevron-double-right</v-icon>Next
          </v-btn>
          <v-btn
            v-if="current_view == 'UPLOAD_LOGO'"
            small
            class="borderRadius text-capitalize"
            color="secondary"
            @click="(current_view = 'CREATE'), (rejectAction = false)"
            ><v-icon small class="mr-1">mdi-chevron-double-left</v-icon>Back
          </v-btn>
          <v-btn
            :loading="loadingREJECT"
            dark
            small
            class="borderRadius"
            color="red"
            @click="approveRejectValidateMethod('REJECTED')"
          >
            <v-icon small class="mr-1">mdi-close</v-icon>
            <div>Reject</div>
          </v-btn>
          <v-btn
            :loading="loadingAPPROVE"
            small
            class="borderRadius"
            color="primary"
            @click="approveRejectValidateMethod('APPROVED')"
          >
            <v-icon small class="mr-1">mdi-check</v-icon>
            <div>Approve</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import { GetAllPartners } from "@/mixins/GetAllPartners.js";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
import { GetAllIssuerTypes } from "@/mixins/GetAllIssuerTypes.js";
// import { ApproveOrRejectPendingIssuer } from "@/graphql/mutations.js";
import { RLTakeActionOnPendingOrganization } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    dialogApproveRejectIssuer: Boolean,
  },
  mixins: [
    GetAllCountryCityStates,
    GetAllSupportedCountry,
    GetAllIssuerTypes,
    GetAllPartners,
  ],
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    imgSrc: null,
    height: 0,
    search: null,
    current_view: "CREATE",
    uploadedImage: false,
    selectedFile: null,
    SnackBarComponent: {},
    create_issuer: {
      issuer_name: "",
      issuer_type: "",
      identification_type: "",
      identification_number: "",
      issuer_address_1: "",
      issuer_address_2: "",
      country: "",
      state: "",
      city: "",
      district: "",
      postal_code: "",
      contact_email_id: "",
      contact_user_name: "",
      country_code: "",
      contact_number: "",
      enable_verification_requests: false,
      issuer_verification_fee: 0,
      currency_type: "",
      account_logo_url: "",
      partner_id: "",
    },
    typeOfBusinessItems: [
      "issuer",
      "Limited Liability Partnership",
      "Sole Proprietorship",
      "Individual",
    ],
    stateList: [],
    citiesList: [],
    identificationTypeItems: [],
    loading: false,
    countryList: [
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "United States",
        dial_code: "+1",
        code: "US",
      },
    ],
    GetAllSupportedCountryList: [],
    currencyItems: ["INR", "DOLLAR"],
    reason: "",
    rejectAction: false,
    loadingAPPROVE: false,
    loadingREJECT: false,
    s3_url: "",
  }),
  watch: {
    "create_issuer.enable_verification_requests"(val) {
      if (val == false) {
        this.create_issuer.currency_type = "INR";
        this.create_issuer.issuer_verification_fee = 0;
      }
    },
    GetAllSupportedCountryList() {
      if (this.StoreObj.action == "VIEW") {
        this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
          (item) => item.country_name == this.StoreObj.country
        )[0].identification_types;
      }
    },
    dialogApproveRejectIssuer(val) {
      if (val == true) {
        this.s3_url = "";
        this.create_issuer.enable_verification_requests = false;
        this.create_issuer.issuer_verification_fee = 0;
        this.GetAllPartnersMethod();
        this.getAllCountriesMethod();
        this.GetAllIssuerTypesMethod();
        if (this.StoreObj.action == "VIEW") {
          this.create_issuer = {
            issuer_name: this.StoreObj.issuer_name,
            identification_type: this.StoreObj.identification_type,
            identification_number: this.StoreObj.identification_number,
            issuer_address_1: this.StoreObj.issuer_address_1,
            issuer_address_2: this.StoreObj.issuer_address_2,
            country: this.StoreObj.country,
            state: this.StoreObj.state,
            city: this.StoreObj.city,
            postal_code: this.StoreObj.postal_code,
            contact_email_id: this.StoreObj.contact_email_id,
            contact_user_name: this.StoreObj.contact_user_name,
            contact_number: this.StoreObj.contact_number,
            country_code: this.StoreObj.country_code,
            issuer_type: this.StoreObj.issuer_type,
            district: this.StoreObj.district,
            enable_verification_requests: this.StoreObj
              .enable_verification_requests,
            issuer_verification_fee: this.StoreObj.issuer_verification_fee,
            currency_type: this.StoreObj.currency_type,
            account_logo_url:
              this.StoreObj.account_logo_url != undefined &&
              this.StoreObj.account_logo_url != null &&
              this.StoreObj.account_logo_url != ""
                ? this.StoreObj.account_logo_url
                : null,
            partner_id: this.StoreObj.partner_id,
          };
        }
      }
    },
    "create_issuer.country"(val) {
      if (
        this.dialogApproveRejectIssuer == true &&
        val != null &&
        val != undefined &&
        val != "" &&
        this.GetAllSupportedCountryList.length != 0
      ) {
        this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
          (item) => item.country_name == this.create_issuer.country
        )[0].identification_types;
        this.getAllStatesMethod(val);
        if (this.StoreObj.action == "CREATE") {
          this.create_issuer.state = "";
          this.create_issuer.city = "";

          this.create_issuer.currency_type = this.countryList.filter(
            (country) => country.name == val
          )[0].currency;
          if (this.$refs.form != undefined) {
            this.$refs.form.reset();
          }
        }
      }
    },
    "create_issuer.state"(val) {
      if (
        this.dialogApproveRejectIssuer == true &&
        val != null &&
        val != undefined &&
        val != "" &&
        this.GetAllSupportedCountryList.length != 0
      ) {
        this.getAllCitiesMethod(val);
      }
    },
  },
  mounted() {
    this.GetAllSupportedCountryMethod("ACTIVE");
    this.height = window.innerHeight - 150;
  },
  methods: {
    async uploadImageToS3() {
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: "ap-south-1",
        accessKeyId: "AKIARIY6IPQHY3TVDGLL",
        secretAccessKey: "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",
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function() {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: "workhistory-mumbai",
          Key: `BMS/Issuer/account_logo/${new Date().getTime()}_${
            self.selectedFile.name
          }`,
          Body: buf,
          ContentType: self.selectedFile.type,
          ACL: "public-read-write",
        };
        s3Bucket.putObject(param, function(err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else if (data) {
            self.s3_url = `https://${
              param.Bucket
            }.s3.ap-south-1.amazonaws.com/${encodeURI(param.Key)}`;
            // this.approveRejectValidateMethod("APPROVE", s3URL);
          }
        });
      };
    },
    checkImagePresent() {
      if (
        this.imgSrc == null ||
        this.imgSrc == "" ||
        this.imgSrc == undefined
      ) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function(val) {
        self.imgSrc = val.target.result;
        self.uploadImageToS3();
      };
    },
    approveRejectValidateMethod(Action) {
      switch (Action) {
        case "APPROVED":
          this.rejectAction = false;
          this.approveRejectMethod(Action);
          break;
        case "REJECTED":
          if (this.current_view == "CREATE") {
            this.current_view = "UPLOAD_LOGO";
            this.rejectAction = true;
          } else {
            if (this.rejectAction == false) {
              this.rejectAction = true;
            } else if (this.rejectAction == true) {
              if (this.$refs.commentForm.validate()) {
                this.approveRejectMethod(Action);
              } else {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "Please Enter the Reason for Rejection.",
                };
              }
            }
          }

          break;
      }
    },
    async approveRejectMethod(action) {
      this[`loading${action}`] = true;
      var inputParams = {
        updater_user_id: this.$store.getters.get_current_user_details.user_id,
        issuer_id: this.StoreObj.issuer_id,
        action: action,
      };
      if (action == "APPROVED" && this.s3_url && this.s3_url !== "") {
        inputParams.account_logo_url = this.s3_url;
      }
      if (action == "REJECTED") {
        inputParams.reason = this.reason;
      }
      try {
        let result = await API.graphql(
          graphqlOperation(RLTakeActionOnPendingOrganization, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(
          result.data.RLTakeActionOnPendingOrganization
        );
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.status_message,
          };

          this.dialogApproveRejectIssuerEmit((this.Toggle = 2));
        }
        this[`loading${action}`] = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };

        this[`loading${action}`] = false;
      }
    },
    dialogApproveRejectIssuerEmit(Toggle) {
      this.current_view = "CREATE";
      this.identificationTypeItems = [];
      this.selectedFile = null;
      this.account_logo_url = null;
      if (this.$refs.form !== undefined) {
        this.$refs.form.reset();
      }

      this.$emit("clicked", Toggle);
    },
  },
};
</script>
