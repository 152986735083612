<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <ApproveRejectIssuerDialog
      :StoreObj="StoreObj"
      :dialogApproveRejectIssuer="dialogApproveRejectIssuer"
      @clicked="dialogApproveRejectIssuerEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="PrimaryFontColor heading1">
              New Issuer Requests
            </div>
          </v-toolbar>
        </v-col>

        <v-toolbar class="elevation-0">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-text-field
              dense
              outlined
              label="Search New Issuers"
              class="FontSize field_label_size field_height borderRadius mt-9"
              v-model="search"
            >
              <template v-slot:prepend-inner>
                <v-icon small class="mr-1">mdi-magnify</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <!-- <v-col cols="12" xs="12" sm="12" md="6">
              <v-select
                dense
                outlined
                label="Status"
                class="FontSize field_label_size field_height borderRadius mt-6 mr-3 maxWidthSmall"
                v-model="issuer_status"
                :items="issuer_statusItems"
              ></v-select>
            </v-col> -->
        </v-toolbar>
        <!-- </v-col> -->
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :search="search"
          :headers="userHeaders"
          :fixed-header="true"
          :no-data-text="noDataText"
          :items="GetAllIssuerUsersList"
          :hide-default-footer="GetAllIssuerUsersList.length == 0"
          :footer-props="{
            'items-per-page-options': [100, 200, 300, 400, 500],
          }"
          :items-per-page="100"
          :disable-sort="$vuetify.breakpoint.xs ? true : false"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllIssuerUsersList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.partner_code`]="{ item }">
            <div class="FontSize">
              {{ item.partner_code != undefined ? item.partner_code : "N/A" }}
            </div>
          </template>
          <template v-slot:[`item.issuer_name`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="FontSize"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="
                    item.issuer_name.length > 15
                      ? `${item.issuer_name.slice(0, 15)}..`
                      : item.issuer_name
                  "
                ></div>
              </template>
              <span v-text="item.issuer_name" class="FontSize"></span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.contact_email_id`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="FontSize"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-text="
                    item.contact_email_id.length > 15
                      ? `${item.contact_email_id.slice(0, 15)}..`
                      : item.contact_email_id
                  "
                ></div>
              </template>
              <span v-text="item.contact_email_id" class="FontSize"></span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.issuer_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.issuer_created_on).toLocaleDateString() }}
              <!-- new Date(item.issuer_created_on).toLocaleString().split(",")[0] -->
            </div>
          </template>
          <template v-slot:[`item.issuer_type`]="{ item }">
            <div class="FontSize">
              {{ item.issuer_type != undefined ? item.issuer_type : "N/A" }}
            </div>
          </template>
          <template v-slot:[`item.total_credits`]="{ item }">
            <div class="FontSize">
              {{ item.total_credits }}
            </div>
          </template>
          <template v-slot:[`item.credits_issued`]="{ item }">
            <div class="FontSize">
              {{ item.credits_issued }}
            </div>
          </template>
          <template v-slot:[`item.available_credits`]="{ item }">
            <div class="FontSize">
              {{ item.available_credits }}
            </div>
          </template>
          <!-- <template v-slot:[`item.issuer_status`]="{ item }">
            <div
              class="FontSize font-weight-bold"
              :class="
                item.issuer_status == 'ACTIVE' ? 'GreenHeader' : 'BlueHeader'
              "
            >
              {{ item.issuer_status }}
            </div>
          </template> -->
          <template v-slot:[`item.source_type`]="{ item }">
            <div class="FontSize">
              {{ item.source_type == undefined ? "SC" : item.source_type }}
            </div>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            <div class="FontSize GreenHeader font-weight-bold">
              {{ item.user_type }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkItem(item, 'VIEW')"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View Details of {{ item.issuer_name }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import ApproveRejectIssuerDialog from "@/components/NewIssuerRequests/Dialogs/ApproveRejectIssuerDialog.vue";
import { GetAllIssuerUsers } from "@/mixins/GetAllIssuerUsers.js";
export default {
  components: {
    ApproveRejectIssuerDialog,
    Snackbar,
    Overlay,
  },
  mixins: [GetAllIssuerUsers],
  data: () => ({
    search: "",
    overlay: false,
    StoreObj: {},
    SnackBarComponent: {},
    dialogApproveRejectIssuer: false,
    GetAllIssuerUsersList: [],
    userHeaders: [
      // {
      //   text: "Partner Code",
      //   value: "partner_code",
      // },
      {
        text: "Name",
        value: "issuer_name",
      },
      {
        text: "Email ID",
        value: "contact_email_id",
      },
      {
        text: "Created On",
        value: "issuer_created_on",
      },
      {
        text: "Source",
        value: "source_type",
      },
      // {
      //   text: "Issuer Type",
      //   value: "issuer_type",
      // },
      {
        text: "Total",
        value: "total_credits",
      },
      {
        text: "Consumed",
        value: "credits_issued",
      },
      {
        text: "Available",
        value: "available_credits",
      },
      // {
      //   text: "Issuer Status",
      //   value: "issuer_status",
      // },
      {
        text: "User Type",
        value: "user_type",
      },
      {
        text: "Actions",
        value: "Actions",
      },
    ],
    issuer_statusItems: ["ALL", "ACTIVE", "INACTIVE", "PENDING"],
    issuer_status: "ALL",
  }),

  mounted() {
    this.GetAllIssuerUsersMethod("PENDING");
  },
  methods: {
    checkItem(item, Action) {
      this.StoreObj = item;
      if (Action == "VIEW") {
        this.StoreObj.action = "VIEW";
        this.dialogApproveRejectIssuer = true;
      }
    },
    dialogApproveRejectIssuerEmit(Toggle) {
      this.StoreObj = {};
      this.dialogApproveRejectIssuer = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod("PENDING");
      }
    },
  },
};
</script>
<style>
.RedHeader {
  color: red;
}
.GreenHeader {
  color: green;
}
.YellowHeader {
  color: yellowgreen;
}
.BlueHeader {
  color: blue;
}
</style>
