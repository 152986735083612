var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('ApproveRejectIssuerDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogApproveRejectIssuer":_vm.dialogApproveRejectIssuer},on:{"clicked":_vm.dialogApproveRejectIssuerEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"PrimaryFontColor heading1"},[_vm._v(" New Issuer Requests ")])])],1),_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-text-field',{staticClass:"FontSize field_label_size field_height borderRadius mt-9",attrs:{"dense":"","outlined":"","label":"Search New Issuers"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","search":_vm.search,"headers":_vm.userHeaders,"fixed-header":true,"no-data-text":_vm.noDataText,"items":_vm.GetAllIssuerUsersList,"hide-default-footer":_vm.GetAllIssuerUsersList.length == 0,"footer-props":{
          'items-per-page-options': [100, 200, 300, 400, 500],
        },"items-per-page":100,"disable-sort":_vm.$vuetify.breakpoint.xs ? true : false},scopedSlots:_vm._u([{key:`item.sl_no`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(_vm.GetAllIssuerUsersList.indexOf(item) + 1)+" ")])]}},{key:`item.partner_code`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.partner_code != undefined ? item.partner_code : "N/A")+" ")])]}},{key:`item.issuer_name`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"FontSize",attrs:{"color":"primary","dark":""},domProps:{"textContent":_vm._s(
                  item.issuer_name.length > 15
                    ? `${item.issuer_name.slice(0, 15)}..`
                    : item.issuer_name
                )}},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"FontSize",domProps:{"textContent":_vm._s(item.issuer_name)}})])]}},{key:`item.contact_email_id`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"FontSize",attrs:{"color":"primary","dark":""},domProps:{"textContent":_vm._s(
                  item.contact_email_id.length > 15
                    ? `${item.contact_email_id.slice(0, 15)}..`
                    : item.contact_email_id
                )}},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"FontSize",domProps:{"textContent":_vm._s(item.contact_email_id)}})])]}},{key:`item.issuer_created_on`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(new Date(item.issuer_created_on).toLocaleDateString())+" ")])]}},{key:`item.issuer_type`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.issuer_type != undefined ? item.issuer_type : "N/A")+" ")])]}},{key:`item.total_credits`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.total_credits)+" ")])]}},{key:`item.credits_issued`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.credits_issued)+" ")])]}},{key:`item.available_credits`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.available_credits)+" ")])]}},{key:`item.source_type`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.source_type == undefined ? "SC" : item.source_type)+" ")])]}},{key:`item.user_type`,fn:function({ item }){return [_c('div',{staticClass:"FontSize GreenHeader font-weight-bold"},[_vm._v(" "+_vm._s(item.user_type)+" ")])]}},{key:`item.Actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.checkItem(item, 'VIEW')}}},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("View Details of "+_vm._s(item.issuer_name))])])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }