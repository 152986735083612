import { ListPartners } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllPartners = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllPartnersList: [],
    };
  },
  methods: {
    async GetAllPartnersMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListPartners, {
            input: {},
          })
        );
        this.GetAllPartnersList = JSON.parse(
          result.data.ListPartners
        ).data.items;
        console.warn("GetAllPartnersList", this.GetAllPartnersList);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
