import { ListIssuers } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuerUsers = {
    data() {
        return {
            overlay: false,
            noDataText:"",
            GetAllIssuerUsersList: []
        };
    },
    methods: {
        async GetAllIssuerUsersMethod(issuer_status) {
            try{
                this.overlay = true;
            let result = await API.graphql(
                graphqlOperation(ListIssuers, {
                    input: {
                        issuer_status:issuer_status
                    },
                })
            );
            this.GetAllIssuerUsersList = JSON.parse(result.data.ListIssuers).data.items
            console.warn("GetAllIssuerUsersList",this.GetAllIssuerUsersList)
            this.overlay = false;
            }catch(error){
                this.overlay = false;
                this.GetAllIssuerUsersList = []
                this.noDataText = error.errors[0].message
            }
            
        },
    },
};
